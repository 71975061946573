.audio {
  margin: 0 8px;
  font-size: 1em;
  width: 50%;
}

.language {
  font-size: 3em;
  margin: 0;
}

.flagContainer {
  font-size: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audioContainer {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 400px;

  margin: 0 auto;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
  }
  @media (min-width: 1680px) {
    height: 20vh;
  }
}
