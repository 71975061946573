.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  padding: 1em 0;
  @media (min-width: 1024px) {
    height: 70vh;
  }
  @media (min-width: 1680px) {
    height: 80vh;
  }
}

.photo {
  max-width: 100%;
  max-height: 100%;
}
