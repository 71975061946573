.button {
  width: 60px;
  height: 60px;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  &:focused {
    box-shadow: 0 0 0 2pt red;
  }
}

.icon {
  width: 100%;
  height: 100%;
}
